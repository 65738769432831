import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import PropTypes from 'prop-types';
import { Modal, ModalHeader } from 'reactstrap';
import Button from '../Button';
// import ResetPin from './ResetPin';
// import { getResetPinOtp } from '../../../store/calls';
// import { ProfileContext } from '../../../store/context/ProfileContext';
export default function PinModal(props) {
  const {
    showModal,
    hideModal,
    otp,
    setOtp,
    separator,
    title,
    description,
    buttonLabel,
    isResendOtp,
    isResendPin,
    handleSubmit,
    handleResendOtpto,
    loading,
    numInputs,
    resendOtp,
    setModalAction,
    setShowResendOtp,
  } = props;

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [counter]);
  return (
    <Modal
      isOpen={showModal}
      // toggle={() => hideModal(false)}
      centered
      id="pin-modal"
    >
      {isResendOtp ? <ModalHeader toggle={() => hideModal(false)} /> : ''}
      <div className="modal-body-rs p-2 pb-3">
        <div className="waya-modal-body mt-3">
          <h1 className="modal-header-data">{title}</h1>

          <p className="modal-header-sub-data text-secondary">{description}</p>

          <OtpInput
            value={otp}
            onChange={(e) => setOtp(e)}
            numInputs={numInputs}
            separator={separator}
            containerStyle={{ justifyContent: 'center' }}
            inputStyle="otp-input otp-bottom"
            isInputSecure
            isInputNum
          />

          <div className="w-100 mt-5 text-center">
            <Button
              type="button"
              className="btn btn-primary btn-lg w-50"
              loading={loading}
              disabled={loading || false}
              onClick={handleSubmit}
              content={buttonLabel}
            />
          </div>
          <div
            disabled
            className={!isResendOtp ? 'd-none' : 'mt-3 text-center'}
          >
            <span
              style={{ cursor: 'pointer' }}
              className={
                !isResendOtp
                  ? 'd-none'
                  : `text-dark decoration-none btn ${
                      counter < 60 ? 'disabled' : ''
                    }`
              }
              onClick={(e) => {
                e.preventDefault();
                // hideModal(false);
                setCounter(0);
                resendOtp(true);
                handleResendOtpto();
              }}
              disabled
              href="/#"
            >
              Resend Code {`${counter < 60 ? `in ${60 - counter}` : ''}`}
            </span>
          </div>
          <div className={!isResendPin ? 'd-none' : 'mt-5 mb-3 text-center'}>
            <a
              href="/#"
              className={!isResendPin ? 'd-none' : 'text-dark decoration-none'}
              onClick={(e) => {
                e.preventDefault();
                setModalAction('resetPin');
                // hideModal(false);
                setShowResendOtp(true);
                // getResetPinOtp(profile.email);
                // setShowResetPin(true);
                // hideModal(false);
              }}
            >
              Reset Pin
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
}

PinModal.defaultProps = {
  separator: <span>-</span>,
  title: '',
  description: '',
  buttonLabel: 'Login',
  numInputs: 4,
  isResendOtp: false,
};

PinModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  // hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  // center: PropTypes.bool.isRequired,
  otp: PropTypes.string.isRequired,
  setOtp: PropTypes.func.isRequired,
  separator: PropTypes.node,
  buttonLabel: PropTypes.string,
  isResendOtp: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  numInputs: PropTypes.number,
  resendOtp: PropTypes.func.isRequired,
};
