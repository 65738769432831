import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Input } from 'antd';
import Swal from 'sweetalert2';
import React from 'react';
import Button from '../../shared/Button';

const AccountListModal = (props) => {
  const {
    showModal,
    hideModal,
    // setPostData,
    // postData,
    verificationMethod,
    setVerificationMethod,
    handleContinue,
    loading,
    methods,
  } = props;

  return (
    <Modal isOpen={showModal} id="" toggle={() => hideModal(false)} centered>
      <ModalHeader className="text-left">Select OTP options</ModalHeader>
      <ModalBody>
        <div className="my-3">
          {methods?.map((e) => (
            <div
              key={e?.method}
              style={{
                cursor: 'pointer',
                backgroundColor:
                  e?.method === verificationMethod?.method ? '#ff6700' : '',
              }}
              className="border w-full h-30 my-4 px-2 py-2"
              onClick={() => {
                setVerificationMethod(e);
                console.log({ met: e });
              }}
            >
              {e?.hint}
            </div>
          ))}
          {verificationMethod?.method ? (
            <div className="my-3">
              <Input
                placeholder="Enter phone number"
                value={verificationMethod?.phone_number}
                onChange={(e) =>
                  setVerificationMethod({
                    ...verificationMethod,
                    phone_number: e.target.value,
                  })
                }
              />
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="w-100 my-5 text-center">
          <Button
            style={{}}
            type="button"
            loading={loading}
            onClick={() => {
              if (verificationMethod?.phone_number) {
                handleContinue();
              } else {
                Swal.fire('Oops!', 'Input phone number');
              }
            }}
            content="Continue"
            className="bg-primary w-50 p-2 rounded-1 text-white border-0"
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AccountListModal;
